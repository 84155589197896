import PropTypes from 'prop-types'
import { Fragment } from 'react'
import {
  Detail,
  Column,
  Alignment,
  Text,
  variants,
} from '@resident-advisor/design-system'
import arrayHasData from 'lib/arrayHasData'
import Link, { hrefAsPropTypes } from '@/components/generic/link'

const LinksColumn = ({ title, items, secondaryComponent, ...columnProps }) => {
  if (!arrayHasData(items)) {
    return null
  }

  return (
    <Column {...columnProps}>
      <Detail title={title}>
        <Alignment flexDirection="row" flexWrap="wrap">
          {items.map((item, index) => (
            <Fragment key={index}>
              {item.href ? (
                <Link
                  variant={variants.text.small}
                  underline
                  href={item.href}
                  as={item.as}
                  target="_blank"
                  rel="noopener"
                >
                  {item.name}
                </Link>
              ) : (
                <Text variant={variants.text.small}>{item.name}</Text>
              )}
              {index < items.length - 1 && (
                <Text variant={variants.text.small} color="primary">
                  &nbsp;·&nbsp;
                </Text>
              )}
            </Fragment>
          ))}
        </Alignment>
        {secondaryComponent}
      </Detail>
    </Column>
  )
}
LinksColumn.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: hrefAsPropTypes,
      as: hrefAsPropTypes,
      name: PropTypes.string,
    })
  ),
  secondaryComponent: PropTypes.node,
}

export default LinksColumn
