import PropTypes from 'prop-types'
import { Column } from '@resident-advisor/design-system'
import FollowDetail from '@/components/shared/follow-detail'
import { FOLLOW_BUTTON_TYPES } from '@/components/shared/follow-button'

const FollowDetailColumn = ({
  id,
  isFollowing,
  type,
  followerCount,
  ...columnProps
}) => (
  <Column {...columnProps}>
    <FollowDetail
      id={id}
      isFollowing={isFollowing}
      type={type}
      followerCount={followerCount}
    />
  </Column>
)

FollowDetailColumn.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isFollowing: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(FOLLOW_BUTTON_TYPES)).isRequired,
  followerCount: PropTypes.number.isRequired,
}

export default FollowDetailColumn
