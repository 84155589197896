import PropTypes from 'prop-types'
import { Column, Detail, variants } from '@resident-advisor/design-system'
import CmsContent from '@/components/generic/cms-content'

const CmsContentColumn = ({ title, content, ...columnProps }) => {
  if (!content) {
    return null
  }

  return (
    <Column {...columnProps}>
      <Detail title={title}>
        <CmsContent content={content} textVariant={variants.text.small} />
      </Detail>
    </Column>
  )
}

CmsContentColumn.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  columnProps: PropTypes.object,
}

export default CmsContentColumn
