import PropTypes from 'prop-types'
import { Detail, Column, variants } from '@resident-advisor/design-system'
import Link from '@/components/generic/link'

const TwitterColumn = ({ twitterUrl, ...columnProps }) => {
  const twitterUsername = parseTwitterUsernameFromUrl(twitterUrl)
  if (!twitterUsername) {
    return null
  }

  return (
    <Column {...columnProps}>
      <Detail title="Twitter">
        <Link href={twitterUrl} variant={variants.text.small} underline>
          {twitterUsername}
        </Link>
      </Detail>
    </Column>
  )
}

const parseTwitterUsernameFromUrl = (twitterUrl) => {
  if (!twitterUrl) {
    return null
  }

  const urlComponents = twitterUrl.split('/')
  if (urlComponents.length <= 1) {
    // not a full URL
    return null
  }

  const last = urlComponents[urlComponents.length - 1]
  return last && `@${last}`
}

TwitterColumn.propTypes = {
  twitterUrl: PropTypes.string,
}

export default TwitterColumn
